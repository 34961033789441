
const Base = [
        {
        'id': 1,
        'img':'pflanzkasten.jpg',
        'name': 'Pflanzkästen',
        'category' : 'Umleitung von Dachwasser',
        'unit': 'planters',
        'calculations': {
            'construction_costs': 100,
            'construction_costs_unit': '€/m²',
            'maintenance_costs_year': 25,
            'life_time_in_years': 25,
            'save_water' : 195
            }
    
        },
        {
            'id': 2,
            'img': 'grundach.jpg',
            'name': 'Gründach',
            'category' : 'Dachwasseraufnahme',
            'unit': 'm²',
            'calculations': {
                'construction_costs': 120,
                'construction_costs_unit': '€/m²',
                'maintenance_costs_year': 7.5,
                'life_time_in_years': 40,
                'save_water' : 57.5
                }
        
            },
            {
                'id': 3,
                'img':'baume.jpg',
                'name': 'Bäume',
                'category' : 'Landschaftsgestaltung',
                'unit': 'Bäume',
                'calculations': {
                    'construction_costs': 250,
                    'construction_costs_unit': '€/Stück',
                    'maintenance_costs_year': 150,
                    'life_time_in_years': 80,
                    'save_water' : 27.2
                    }
            
                },
                {
                    'id': 4,
                    'img' : 'rigole.jpg',
                    'name': 'Rigole',
                    'category' : 'Landschaftsgestaltung',
                    'unit': 'm',
                    'calculations': {
                        'construction_costs': 175,
                        'construction_costs_unit': '€/m²',
                        'maintenance_costs_year': 2.5,
                        'life_time_in_years': 20,
                        'save_water' : 17.005
                        }
                
                    },
                    {
                        'id': 5,
                        'img': 'regenfass.jpg',
                        'name': 'Regenfass',
                        'category' : 'Dachwasseraufnahme',
                        'unit': 'Anzahl',
                        'calculations': {
                            'construction_costs': 100,
                            'construction_costs_unit': '€/Stück',
                            'maintenance_costs_year': 10,
                            'life_time_in_years': 20,
                            'save_water' : 200
                            }
                    
                        },
                        {
                            'id': 6,
                            'img' : 'zisterne.jpg',
                            'name': 'Zisterne',
                            'category' : 'Dachwasseraufnahme',
                            'unit': 'Anzahl',
                            'calculations': {
                                'construction_costs': 1400,
                                'construction_costs_unit': '€/Stück',
                                'maintenance_costs_year': 80,
                                'life_time_in_years': 40,
                                'save_water' : 4000
                                }
                            },
                            {
                                'id': 7,
                                'img': 'fundament.jpg',
                                'name': 'Fundament - Umfangsablauf',
                                'category' : 'Dachwasseraufnahme',
                                'unit': 'm',
                                'calculations': {
                                    'construction_costs': 140,
                                    'construction_costs_unit': '€/m',
                                    'maintenance_costs_year': 0,
                                    'life_time_in_years': 40,
                                    'save_water' : 272.1
                                    }
    
                                    
                            
                                },
                                {
                                    'id': 8,
                                    'img': 'regengarten.jpg',
                                    'name': 'Regengarten',
                                    'category' : 'Umleitung von Dachwasser',
                                    'unit': 'm2',
                                    'calculations': {
                                        'construction_costs': 60,
                                        'construction_costs_unit': '€/m2',
                                        'maintenance_costs_year': 4,
                                        'life_time_in_years': 22.5,
                                        'save_water' : 328.4
                                        }
                                    },
                                    {
                                        'id': 9,
                                        'img':'vorfluter.jpg',
                                        'name': 'Vorfluter',
                                        'category' : 'Umleitung von Dachwasser',
                                        'unit': 'Anzahl',
                                        'calculations': {
                                            'construction_costs': 150,
                                            'construction_costs_unit': '€/Stück',
                                            'maintenance_costs_year': 12.5,
                                            'life_time_in_years': 70,
                                            'save_water' : 2.5
                                            }
                                        },
                                        {
                                            'id': 10,
                                            'img' : 'geanderter.jpg',
                                            'name': 'Geänderter Boden',
                                            'category' : 'Landschaftsgestaltung',
                                            'unit': 'm2',
                                            'calculations': {
                                                'construction_costs': 3,
                                                'construction_costs_unit': '€/m2',
                                                'maintenance_costs_year': 0,
                                                'life_time_in_years': 100,
                                                'save_water' : 525
                                                }
                                            },
                                            {
                                                'id': 11,
                                                'img': 'urban_farming.jpg',
                                                'name': 'Urban Farming - Gartenbau',
                                                'category' : 'Landschaftsgestaltung',
                                                'unit': 'm2',
                                                'calculations': {
                                                    'construction_costs': 3,
                                                    'construction_costs_unit': '€/m2',
                                                    'maintenance_costs_year': 0,
                                                    'life_time_in_years': 100,
                                                    'save_water' : 0.525
                                                    }
                                                },
                                                {
                                                    'id': 12,
                                                    'img': 'pflanzstreifen.jpg',
                                                    'name': 'Pflanzstreifen',
                                                    'category' : 'Landschaftsgestaltung',
                                                    'unit': 'm2',
                                                    'calculations': {
                                                        'construction_costs': 2,
                                                        'construction_costs_unit': '€/m2',
                                                        'maintenance_costs_year': 0.2,
                                                        'life_time_in_years': 30,
                                                        'save_water' : 1.58103
                                                        }
                                                    },
                                                    {
                                                        'id': 13,
                                                        'img': 'einheimische_vegetation.jpg',
                                                        'name': 'Einheimische Vegetation',
                                                        'category' : 'Landschaftsgestaltung',
                                                        'unit': 'm2',
                                                        'calculations': {
                                                            'construction_costs': 2,
                                                            'construction_costs_unit': '€/m2',
                                                            'maintenance_costs_year': 0.5,
                                                            'life_time_in_years': 100,
                                                            'save_water' : 70
                                                            }
                                                        },
                                                        {
                                                            'id': 14,
                                                            'img': 'parkplatz_rigolen.jpg',
                                                            'name': 'Parkplatz Rigolen',
                                                            'category' : 'Steuerung des Abflusses',
                                                            'unit': 'm2',
                                                            'calculations': {
                                                                'construction_costs': 360,
                                                                'construction_costs_unit': '€/m2',
                                                                'maintenance_costs_year': 20,
                                                                'life_time_in_years': 25,
                                                                'save_water' : 54.
                                                                }
                                                            },
                                              
                                                                {
                                                                    'id': 15,
                                                                    'img': 'rigolen_am_straßenrand.jpg',
                                                                    'name': 'Rigolen am Straßenrand',
                                                                    'category' : 'Steuerung des Abflusses',
                                                                    'unit': 'm2',
                                                                    'calculations': {
                                                                        'construction_costs': 360,
                                                                        'construction_costs_unit': '€/m2',
                                                                        'maintenance_costs_year': 20,
                                                                        'life_time_in_years': 25,
                                                                        'save_water' : 765
                                                                        }
                                                                    },
                                                                    {
                                                                        'id': 16,
                                                                        'img': 'a1.jpg',
                                                                        'name': 'Durchlässiger Untergrund  - Terrasse',
                                                                        'category' : 'Durchlässiger Bodenbelag',
                                                                        'unit': 'm2',
                                                                        'calculations': {
                                                                            'construction_costs': 90,
                                                                            'construction_costs_unit': '€/m2',
                                                                            'maintenance_costs_year': .25,
                                                                            'life_time_in_years': 30,
                                                                            'save_water' : 275

                                                                            }
                                                                        },
                                                                        {
                                                                            'id': 17,
                                                                            'img': 'a2.jpg',
                                                                            'name': 'Durchlässiger Untergrund - Parkplatz',
                                                                            'category' : 'Durchlässiger Bodenbelag',
                                                                            'unit': 'm2',
                                                                            'calculations': {
                                                                                'construction_costs': 90,
                                                                                'construction_costs_unit': '€/m2',
                                                                                'maintenance_costs_year': .25,
                                                                                'life_time_in_years': 30,
                                                                                'save_water' : 275
    
                                                                                }
                                                                            },
                                                                            {
                                                                                'id': 18,
                                                                                'img': 'a3.jpg',
                                                                                'name': 'Durchlässiger Untergrund - Gehweg' ,
                                                                                'category' : 'Durchlässiger Bodenbelag',
                                                                                'unit': 'm2',
                                                                                'calculations': {
                                                                                    'construction_costs': 90,
                                                                                    'maintenance_costs_year': .25,
                                                                                    'life_time_in_years': 30,
                                                                                    'save_water' : 275
        
                                                                                    }
                                                                                },
                                                                                {
                                                                                    'id': 19,
                                                                                    'img': 'a4.jpg',
                                                                                    'name': 'Durchlässiger Untergrund - Auffahrt',
                                                                                    'category' : 'Durchlässiger Bodenbelag',
                                                                                    'unit': 'm2',
                                                                                    'calculations': {
                                                                                        'construction_costs': 90,
                                                                                        'construction_costs_unit': '€/m2',
                                                                                        'maintenance_costs_year': .25,
                                                                                        'life_time_in_years': 30,
                                                                                        'save_water' : 275
            
                                                                                        }
                                                                                    },
                                                                                    {
                                                                                        'id': 20,
                                                                                        'img': 'a5.jpg',
                                                                                        'name': 'Durchlässiger Untergrund - Strasse',
                                                                                        'category' : 'Durchlässiger Bodenbelag',
                                                                                        'unit': 'm2',
                                                                                        'calculations': {
                                                                                            'construction_costs': 90,
                                                                                            'construction_costs_unit': '€/m2',
                                                                                            'maintenance_costs_year': .25,
                                                                                            'life_time_in_years': 30,
                                                                                            'save_water' : 275
                
                                                                                            }
                                                                                        },
                                                            

];

export default Base