import {
  AiOutlineCalculator,
  AiOutlinePlusSquare,
  AiOutlineDatabase,
  AiOutlineBarChart,
  AiOutlineFilePdf,
} from "react-icons/ai";
import { MdHomeWork, MdGarage, MdCompareArrows } from "react-icons/md";

const AddToCompare = () => {
  return (
    <div className="w-full flex gap-6 justify-center">
      <button className="bg-lime-500 text-white py-2 px-24 inline-flex items-center hover:bg-lime-600">
        <AiOutlineFilePdf className="mr-3"></AiOutlineFilePdf>Als PDF speichern
      </button>
      <button className="bg-lime-500 text-white py-2 px-24 inline-flex items-center hover:bg-lime-600">
        <MdCompareArrows className="mr-3"></MdCompareArrows>
        Zur Vergleichsliste hinzufügen
      </button>
    </div>
  );
};

export default AddToCompare;
