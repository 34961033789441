import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GrafischeAusgabe = ({ v1, v2 }) => {
  const COLORS = ["#999999", "#65a30c"];
  const data01 = [
    { name: "Grundfläche", value: v1 },
    { name: "Hausfläche", value: v2 },
  ];
  return (
    <div className="mt-2">
      <div className=" ">
        <hr />

        <div className="w-[20px]"></div>

        <div className="block"></div>
      </div>
      <div className="  ">
        <div className="">
          <PieChart width={480} height={280}>
            <Pie
              data={data01}
              dataKey="value"
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#65a30c"
              label
            >
              {data01.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </div>
        <div className=""></div>
      </div>
    </div>
  );
};

export default GrafischeAusgabe;
