const ProjectInfo = () => {
  return (
    <div className="">
      <h1 className="text-3xl text-lime-600 p-4">Überschrift</h1>

      <div className="">
        <div className=" bg-gray-100 p-5 flex mb-5">
          <div className="">
            <h4 className="text-2xl text-gray-500 text-boldr">
              {" "}
              Ziele und Informationen zum Projekt...
            </h4>

            <p className="my-4 pr-5">
              Lorem ipsum dolor sit amet, consectetur adipisici elitLorem ipsum
              dolor sit amet, consectetur adipisici elitLorem ipsum dolor sit
              amet, consectetur adipisici elit, Lorem ipsum dolor sit amet,
            </p>
          </div>

          <div className="mt-[-100px] ">
            <img src="/images/img_p.jpg" alt=""></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;
