import { BsPower } from "react-icons/bs";
import { AiOutlineCheckSquare } from "react-icons/ai";
import { ImCheckboxUnchecked } from "react-icons/im";
import { BsPatchQuestion } from "react-icons/bs";
import { HiDotsHorizontal } from "react-icons/hi";
import { useEffect, useState } from "react";
import Fade from "@mui/material/Fade";
import { Tooltip } from "react-tooltip";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
// import { useEffect } from 'react';

const NewItemImpr = (props) => {
  const [showCalc, setShowCalc] = useState(false);
  const [showCalcNew, setShowCalcNew] = useState(false);
  const [howItem, setHowItem] = useState(1);

  useEffect(() => {
    if(props.close == false){
      setShowCalc(false)
    }

  });

  const changeHowItem = (e) => {
    setHowItem(e.target.value);
    if (showCalc) {
      props.setSaveWater(
        parseInt(props.saveWater) +
          parseInt(
            (props.impr.calculations.save_water * e.target.value).toFixed(2)
          )
      );
    } else {
      props.setSaveWater(
        parseInt(props.saveWater) -
          parseInt(
            (props.impr.calculations.save_water * e.target.value).toFixed(2)
          )
      );
    }
  };

  return (
    <div className={"bg-white  w-full relative border "}>
      {!showCalc && (
        <div className="absolute top-2 left-2 text-[10px] leading-[.7rem] bg-pink-600 text-white rounded p-1 z-[100] text-center">
          Wasser <br /> gespart
          <div className="text-center text-3xl font-bold">0</div>
          <div className="text-center">litre</div>
        </div>
      )}
      {showCalc && (
        <div className="absolute top-2 left-2 text-[10px] leading-[.7rem] bg-lime-600 text-white rounded p-1 z-[100] text-center">
          Wasser gespart
          <div className="text-center text-3xl font-bold">
            {(props.impr.calculations.save_water * howItem).toFixed(0)}
          </div>
          <div className="text-center">litre</div>
        </div>
      )}

      <div className={showCalc ? "grayscale-0 shadow-md " : "grayscale"}>
        <div className="bg-white ">
          <img src={"/img/" + props.impr.img}></img>
        </div>

        <div className="p-3 relative">
          <h2 className="text-xl text-lime-600 font-bold">
            {props.impr.name}
            <a id={"props-amo1"} className="absolute pl-1 pt-1">
              <BsPatchQuestion color={"#000"} size={16}></BsPatchQuestion>
            </a>
            <Tooltip anchorId={"props-amo1"} content={"Abflussleistung info"} />
          </h2>
          <div className="h4 text-sm text-gray-400">{props.impr.category}</div>

          <div className="absolute top-0 right-0 block ">
            <div className="">
              <a id={"new-imp-" + props.impr.id}>
                <Switch
                  onClick={() => {
                    props.setClose(true)
                    setShowCalc(!showCalc);
                    if (!showCalc) {
                      props.setSaveWater(
                        parseInt(props.saveWater) +
                          parseInt(
                            props.impr.calculations.save_water *
                              howItem.toFixed(0)
                          )
                      );
                    } else {
                      props.setClose(true)
                      props.setSaveWater(
                        parseInt(props.saveWater) -
                          parseInt(
                            props.impr.calculations.save_water *
                              howItem.toFixed(0)
                          )
                      );
                    }
                  }}
                />
              </a>
            </div>

            <div
              className="text-2xl text-lime-500 text-right pr-3 mb-2 flex justify-end"
              onClick={() => setShowCalcNew(!showCalcNew)}
            >
              <HiDotsHorizontal></HiDotsHorizontal>
            </div>

            <Tooltip
              anchorId={"new-imp-" + props.impr.id}
              content={"Fügen Sie nachhaltige Aktivitäten hinzu"}
            />
          </div>
        </div>

        {showCalcNew && (
          <>
            <div className="px-4">
              <div className="bg-gray-100 p-2 text-center">
                Oberfläche:{" "}
                <input
                  type="text"
                  value={howItem}
                  onChange={changeHowItem}
                  className="ml-6 text-center"
                />
              </div>
              <div className="text-center">. . .</div>

              <div className="grid grid-cols-3 pb-3 ">
                <div className="col-span-2">
                  <div>
                    <label></label>
                  </div>

                  <div className="text-[12px] font-bold ">
                  Investitionskosten :
                  </div>
                  <div className="text-[12px] font-bold">Laufende Kosten :</div>
                  <div className="text-[12px] font-bold">
                    Typische Nutzungsdauer :
                  </div>
                </div>
                <div className="">
                  <div className="text-[12px] ">
                    {props.impr.calculations.construction_costs * howItem}{" "}
                    <span className="text-[9px]">
                      ({props.impr.calculations.construction_costs_unit})
                    </span>{" "}
                  </div>
                  <div className="text-[12px] ">
                    {props.impr.calculations.maintenance_costs_year * howItem}{" "}
                    <span className="text-[9px]">
                      (
                      {props.impr.calculations.construction_costs_unit +
                        "/Jahr"}
                      )
                    </span>
                  </div>
                  <div className="text-[12px] ">
                    {props.impr.calculations.life_time_in_years} (Jahre)
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NewItemImpr;
