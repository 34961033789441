import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import ProjectInfo from "./components/ProjectInfo";
import HomeItem from "./components/HomeItem";
import { useState } from "react";
import ObjectItem from "./components/ObjectItem";
import { IoIosQrScanner } from "react-icons/io";
import UrbanHome from "./components/UrbanHome";
import { MdHomeWork, MdGarage, MdCompareArrows } from "react-icons/md";
import { TbSquare } from "react-icons/tb";
import ImprAll from "./components/ImprAll";
// import Amortisation from "./components/Amortisation";
// import AddToCompare from "./components/AddToCompare";
import Vergleichsliste from "./components/Vergleichsliste";
import NewHomeItem from "./components/NewHomeItem";

function App() {
  const [hausfItem, setHausfItem] = useState(200);
  const [hausflache, setHausflache] = useState(200);

  const [versiegelte, setVersiegelte] = useState(200);
  const [totalArea, setTotalArea] = useState(200);
  const [itemArea, setItemArea] = useState(1000);
  const [grunflache, setGrunflache] = useState(0);
  const [niederschalagsmenge, setNiederschalagsmenge] = useState(300);
  const [nameItem, setNameItem] = useState("");
  // pokaz

  const [show, setShow] = useState(true);

  // img
  const [imgInw, setImgInw] = useState("images/home1.png");
  // szer dł elementu

  const [defWidthEl, setDefWidthEl] = useState(33.15);
  const [defHeightEl, setDefHeightEl] = useState(30.15);

  const descAll = [
    {
      grund: 1000,
      hausf: 360,
      versiegelung: 60,
    },
    {
      grund: 3400,
      hausf: 880,
      versiegelung: 72,
    },
    {
      grund: 12000,
      hausf: 2400,
      versiegelung: 40,
    },
    {
      grund: 8000,
      hausf: 2000,
      versiegelung: 56,
    },
  ];

  // style

  const [a, setA] = useState("");
  const [b, setB] = useState("");
  const [c, setC] = useState("");
  const [d, setD] = useState("");


  const [notShowOther, setNotShowOther] = useState(true)
  const [showLandflache, setShowLandflache] = useState(false)
  const [showLandflache2, setShowLandflache2] = useState(false)

  const Einfamilienhaus = () => {
    setGrunflache(1000);
    setHausfItem(360);
    setHausflache(360);
    setVersiegelte(0);
    setImgInw("images/home1.png");
    setDefWidthEl(33.15);
    setDefHeightEl(30.15);
    setA(
      "border-t-4 border-l-4 border-r-4 border-t-lime-500 border-r-lime-500 border-l-lime-500 "
    );
    setB("border-b-4 border-b-lime-500");
    setC("border-b-4 border-b-lime-500");
    setD("border-b-4 border-b-lime-500");
    setShow(true);
    setNotShowOther(true);
    setShowLandflache(true);
    
  };
  const Mehrfamilienhaus = () => {
    setGrunflache(3400);
    setHausfItem(880);
    setHausflache(880);
    setVersiegelte(0);
    setImgInw("images/home2.png");
    setDefWidthEl(50);
    setDefHeightEl(68);
    setB(
      "border-t-4 border-l-4 border-r-4 border-t-lime-500 border-r-lime-500 border-l-lime-500 "
    );
    setA("border-b-4 border-b-lime-500");
    setC("border-b-4 border-b-lime-500");
    setD("border-b-4 border-b-lime-500");
    setShow(false);
    setNotShowOther(true);
    setShowLandflache(false);
  };
  const Gewerbeimmobilie = () => {
    setGrunflache(12000);
    setHausfItem(2400);
    setHausflache(2400);
    setVersiegelte(0);
    setImgInw("images/home3.png");
    setDefWidthEl(50);
    setDefHeightEl(68);
    setC(
      "border-t-4 border-l-4 border-r-4 border-t-lime-500 border-r-lime-500 border-l-lime-500 "
    );
    setB("border-b-4 border-b-lime-500");
    setA("border-b-4 border-b-lime-500");
    setD("border-b-4 border-b-lime-500");
    setShow(false);
    setNotShowOther(false);
    setShowLandflache(true);
    setShowLandflache2(true);
  };
  const Schulgebaude = () => {
    setGrunflache(8000);
    setHausfItem(2000);
    setHausflache(2000);
    setVersiegelte(0);
    setImgInw("images/home4.png");
    setDefWidthEl(50);
    setDefHeightEl(68);
    setD(
      "border-t-4 border-l-4 border-r-4 border-t-lime-500 border-r-lime-500 border-l-lime-500 "
    );
    setB("border-b-4 border-b-lime-500");
    setC("border-b-4 border-b-lime-500");
    setA("border-b-4 border-b-lime-500");
    setShow(false);
    setNotShowOther(false);
    setShowLandflache(true);
    setShowLandflache2(false);
  };
  // const all = [
  //   ['300','-','-']
  // ]

  const [compare, setCompare] = useState([]);
  const [value, setValue] = useState(12000);

 
  
  return (
    <div className="App">
      <Header></Header>
      <div className="w-full  md:w-5/6  lg:w-3/4 xl:w-3/4 mx-auto py-10 bg-white">
        <div className="" id="projektinfo">
          <ProjectInfo></ProjectInfo>
        </div>
        <div className="">
          <h3 className="text-3xl my-4 text-lime-600">
            Vorbelegungen - Immobilientyp{" "}
          </h3>
        </div>

        {/*  */}

        <div className="flex gap-2">
          <div
            className="w-1/4   cursor-pointer"
            onClick={Einfamilienhaus}
            onMouseEnter={Einfamilienhaus}
          >
            <HomeItem
              className="bg-[#ff0000]"
              img={"images/home1.png"}
              name={"Einfamilienhaus"}
              grund={descAll[0].grund}
              hausf={descAll[0].hausf}
              versiegelung={descAll[0].versiegelung}
              a={a}
             
            ></HomeItem>
          </div>

          <div
            className="w-1/4  cursor-pointer"
            onClick={Mehrfamilienhaus}
            onMouseEnter={Mehrfamilienhaus}
          >
            <HomeItem
              img={"images/home2.png"}
              name={"Mehrfamilienhaus"}
              grund={descAll[1].grund}
              hausf={descAll[1].hausf}
              versiegelung={descAll[1].versiegelung}
              a={b}
            ></HomeItem>
          </div>

          <div
            className="w-1/4  cursor-pointer"
            onClick={Gewerbeimmobilie}
            onMouseEnter={Gewerbeimmobilie}
          >
            <HomeItem
              img={"images/home3.png"}
              name={"Gewerbeimmobilie"}
              grund={descAll[2].grund}
              hausf={descAll[2].hausf}
              versiegelung={descAll[2].versiegelung}
              a={c}
            ></HomeItem>
          </div>
          <div
            className="w-1/4  cursor-pointer"
            onClick={Schulgebaude}
            onMouseEnter={Schulgebaude}
          >
            <HomeItem
              img={"images/home4.png"}
              name={"Schulgebäude"}
              grund={descAll[3].grund}
              hausf={descAll[3].hausf}
              versiegelung={descAll[3].versiegelung}
              a={d}
            ></HomeItem>
          </div>
        </div>

        {/*  */}

        {/* Individuelle Dateneingabe:  */}
        {/*  */}
       {/*    <div className="bg-gray-100 border-b-4 border-l-4 border-r-4 border-b-lime-500 border-r-lime-500 border-l-lime-500 ">
          <div className="flex  h-full">



          <div className="w-full flex ">
              <div className="w-1/4">
                <ObjectItem
                  icon={<IoIosQrScanner size={30} />}
                  id={UrbanHome[0].id}
                  nameItem={UrbanHome[0].name}
                  setNameItem={setNameItem}
                  subNameItem={UrbanHome[0].subname}
                  itemArea={itemArea}
                  setItemArea={setItemArea}
                  info={UrbanHome[0].info}
                  totalarea={UrbanHome[0].totalarea}
                  setTotalArea={setTotalArea}
                  defWidth={defWidthEl}
                  defHeight={defHeightEl}
                  totalArea={totalArea}
                />
              </div>
              <div className="w-1/4">
                <ObjectItem
                  icon={<MdHomeWork size={30} color={"brown"} />}
                  id={UrbanHome[1].id}
                  nameItem={UrbanHome[1].name}
                  setNameItem={setNameItem}
                  subNameItem={UrbanHome[1].subname}
                  itemArea={itemArea}
                  setItemArea={setItemArea}
                  info={UrbanHome[1].info}
                  totalarea={UrbanHome[1].totalarea}
                  setTotalArea={setHausfItem}
                  totalArea={hausfItem}
                  defWidth={defWidthEl}
                  defHeight={defHeightEl}
                />
              </div>
              <div className="w-1/4">
                <ObjectItem
                  icon={<TbSquare size={30} color={"black"} />}
                  id={UrbanHome[2].id}
                  nameItem={UrbanHome[2].name}
                  setNameItem={setNameItem}
                  subNameItem={UrbanHome[2].subname}
                  itemArea={itemArea}
                  setItemArea={setItemArea}
                  info={UrbanHome[2].info}
                  totalarea={UrbanHome[2].totalarea}
                  setTotalArea={setVersiegelte}
                  totalArea={versiegelte}
                  defWidth={defWidthEl}
                  defHeight={defHeightEl}
                />
              </div>
              <div className="w-1/4">
                <ObjectItem
                  icon={<TbSquare size={30} color={"black"} />}
                  id={UrbanHome[3].id}
                  nameItem={UrbanHome[3].name}
                  setNameItem={setNameItem}
                  subNameItem={UrbanHome[3].subname}
                  itemArea={itemArea}
                  setItemArea={setItemArea}
                  info={UrbanHome[3].info}
                  totalarea={UrbanHome[3].totalarea}
                  setTotalArea={setNiederschalagsmenge}
                  totalArea={niederschalagsmenge}
                  defWidth={defWidthEl}
                  defHeight={defHeightEl}
                />
              </div>
            </div> 
          
          </div>
        </div>*/}
  {/* end */}
<NewHomeItem
grunflache = {grunflache}
hausflache = {hausflache}
versiegelte = {versiegelte}
niederschalagsmenge = {niederschalagsmenge}
setGrunflache = {setGrunflache}
setHausflache = {setHausflache}
setVersiegelte = {setVersiegelte}
notShowOther = {notShowOther}
showLandflache = {showLandflache}
>



</NewHomeItem>

        <ImprAll
          imgInw={imgInw}
          compare={compare}
          setCompare={setCompare}
          value = {value}
          setValue = {setValue}
        ></ImprAll>

        {/* <Amortisation value = {value} ></Amortisation> */}
    

        <Vergleichsliste
          compare={compare}
          setCompare={setCompare}
          invest = {value}
        ></Vergleichsliste>
      </div>
    </div>
  );
}

export default App;
