import Base from "./Base";

const Filter = ({ menuItems, filterItem, setItem, cur, setCur }) => {
  return (
    <>
      <div className="flex mb-1">
        <button
          className={`bg-gradient-to-tr from-lime-500 to-lime-600 text-white py-2 px-4 rounted  ${
            cur == "alles" ? "bg-gradient-to-tr from-lime-700 to-lime-600" : ""
          }`}
          onClick={() => {
            setCur("alles");
            setItem(Base);
          }}
        >
          Alles
        </button>

        {menuItems.map((Val, id) => {
          return (
            <>
              <button
                className={`bg-gradient-to-tr from-lime-500 to-lime-600 text-white py-2 px-4 rounted  ${
                  Val == cur
                    ? "bg-gradient-to-tr from-lime-700 to-lime-600"
                    : ""
                }`}
                onClick={() => filterItem(Val)}
                key={id}
              >
                {Val}
              </button>
            </>
          );
        })}
      </div>
    </>
  );
};

export default Filter;
