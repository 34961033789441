import { IoIosQrScanner } from "react-icons/io";
import { MdHomeWork } from "react-icons/md";
import { TbSquare } from "react-icons/tb";
import { BsCloudRainHeavy } from "react-icons/bs";
import { BsPatchQuestion } from "react-icons/bs";
import { HiDotsHorizontal } from "react-icons/hi";
import { AiOutlineSave } from "react-icons/ai";
import { useState } from "react";
import { Tooltip } from "react-tooltip";

const NewHomeItem = ({ totalArea, hausflache, grunflache, versiegelte, setHausflache,setGrunflache,setVersiegelte ,notShowOther, showLandflache,showLandflache2}) => {
  const [toggleG, setToggleG] = useState(true);
  const [toggleH, setToggleH] = useState(true);
  const [toggleV, setToggleV] = useState(true);
  const [toggleN, setToggleN] = useState(true);
  const [isDisabledG, setIsDisabledG] = useState(false);
  const [isDisabledH, setIsDisabledH] = useState(false);
  const [isDisabledV, setIsDisabledV] = useState(false);
  const [isDisabledN, setIsDisabledN] = useState(false);

  const changeLandflache = (e) => {

    setHausflache(e.target.value)
  
  }
  const changeLandflacheProc = (e) => {
    setHausflache(grunflache * e.target.value * 0.01)
  }
  const changeGrunflache = (e) => {
    setGrunflache(e.target.value)
  }
const changeVersiegelte = (e) => {
    setVersiegelte(e.target.value)
}
const changeVersiegelteProc = (e) => {
    setVersiegelte(grunflache * e.target.value * 0.01)
}



  return (
    <div className="bg-white flex border-b-4 border-l-4 border-r-4 border-b-lime-500 border-r-lime-500 border-l-lime-500">
      <div className="w-1/4 relative py-5">
       <div className="absolute left-3  top-8">
       <IoIosQrScanner size={30} />
       </div>

        <div className="">
          Grundstücksfläche  
          <a id={"props-basic"} className="absolute pl-1 pt-1">
            <BsPatchQuestion></BsPatchQuestion>
          </a>
          <Tooltip anchorId={"props-basic"} content="Grundstücksfläche" />
        </div>

        <div className="text-sm">
          {" "}
          <span className="font-bold">
            {grunflache} m<sup>2</sup>
          </span>
          <div className="text-xs">(100% der Gesamtfläche)</div>
        </div>
        <hr></hr>
        <div
          className="absolute text-lime-500 text-2xl top-6 right-1 z-20"
          onClick={() => {
            setToggleG(!toggleG);
          }}
        >
          <HiDotsHorizontal></HiDotsHorizontal>
        </div>
        {!toggleG && (
          <div className="text-sm justify-center my-1 flex gap-1">
            <label>
            Grundfläche m <sup>2</sup>{" "}
            </label>
            <input
             disabled={isDisabledG}
              type="number"
              min="1"
              className="border border-lime-600 w-12"
              onChange={changeGrunflache}
            ></input>
            <AiOutlineSave size={20} className="text-lime-800" onClick={(() => setIsDisabledG(!isDisabledG))}></AiOutlineSave>
          </div>
        )}
      </div>
      <div className="w-1/4 relative py-5">
      <div className="absolute left-3  top-8">
        <MdHomeWork size={30} color={"brown"} />
        </div>
        <div className="">
        <b> Hausfläche</b> 
          <a id={"props-basic0"} className="absolute pl-1 pt-1">
            <BsPatchQuestion></BsPatchQuestion>
          </a>
          <Tooltip anchorId={"props-basic0"} content="Hausfläche" />
        </div>
        <div className="text-sm">
          {" "}
          <span className="font-bold">
            {hausflache} m<sup>2</sup>
          </span>
        </div>
        <div className="text-xs">(100% der Gesamtfläche)</div>
        <hr />
        <div
          className="absolute text-lime-500 text-2xl top-6 right-1 z-20"
          onClick={() => {
            setToggleH(!toggleH);
          }}
        >
          <HiDotsHorizontal></HiDotsHorizontal>
        </div>
        {!toggleH && (
            
        <div className="">

{showLandflache && 
              <div className="text-sm justify-center my-1 flex gap-1">
            <label>
            Landfläche m <sup>2</sup>{" "} 
            </label>
            <input
               disabled={isDisabledH}
              type="number"
              min="1"
              className="border border-lime-600 w-12"
              onChange={changeLandflache}
            ></input>
            <AiOutlineSave size={20} className="text-lime-800" onClick={(() => setIsDisabledH(!isDisabledH))}></AiOutlineSave>
          </div>

}

          <div className="text-sm justify-center my-1 flex gap-1">
            <label>
            Grundfläche %
            </label>
            <input
            disabled={isDisabledH}
              type="number"
              min="1"
              className="border border-lime-600 w-12"
              onChange={changeLandflacheProc}
            ></input>
            <AiOutlineSave size={20} className="text-lime-800" onClick={(() => setIsDisabledH(!isDisabledH))}></AiOutlineSave>
          </div>
        </div>
        )}
      </div>


      {!notShowOther  && 

      <div className="w-1/4 relative py-5">
      <div className="absolute left-3  top-8">
        <TbSquare size={30} color={"black"} />
        </div>

        <div className="">
          Versiegelte Fläche//{notShowOther}
          <a id={"props-basicc"} className="absolute pl-1 pt-1">
            <BsPatchQuestion></BsPatchQuestion>
          </a>
          <Tooltip anchorId={"props-basicc"} content=" Versiegelte Fläche" />
        </div>


        <div className="text-sm">
          {" "}
          <span className="font-bold">
            {versiegelte} m<sup>2</sup>
          </span>
        </div>
        <div className="text-xs">(100% der Gesamtfläche)</div>
        <hr />
        <div
          className="absolute text-lime-500 text-2xl top-6 right-1 z-20"
          onClick={() => {
            setToggleV(!toggleV);
          }}
        >
          <HiDotsHorizontal></HiDotsHorizontal>
        </div>
        {!toggleV && (
       <div className="">
             {showLandflache2 && 
              <div className="text-sm justify-center my-1 flex gap-1">
            <label>
            Landfläche m <sup>2</sup>{" "}
            </label>
            <input
               disabled={isDisabledV}
              type="number"
              min="1"
              className="border border-lime-600 w-12"
              onChange={changeVersiegelte}
            ></input>
            <AiOutlineSave size={20} className="text-lime-800" onClick={(() => setIsDisabledV(!isDisabledV))}></AiOutlineSave>
          </div>
        }


          <div className="text-sm justify-center my-1 flex gap-1">
            <label>
            Grundfläche %
            </label>
            <input
            disabled={isDisabledV}
              type="number"
              min="1"
              className="border border-lime-600 w-12"
              onChange={changeVersiegelteProc}
            ></input>
            <AiOutlineSave size={20} className="text-lime-800" onClick={(() => setIsDisabledV(!isDisabledV))}></AiOutlineSave>
          </div>
       </div>
        )}
      </div>

        }




{!notShowOther  && 
      <div className="w-1/4 relative py-5">
      <div className="absolute left-3  top-8">
        <BsCloudRainHeavy size={30} />
        </div>
        <div className="">
          Niederschalagsmenge
          <a id={"props-basicd"} className="absolute pl-1 pt-1">
            <BsPatchQuestion></BsPatchQuestion>
          </a>
          <Tooltip anchorId={"props-basicd"} content="Niederschalagsmenge" />
        </div>

        <div className="text-sm">
          {" "}
          <span className="font-bold">
            {versiegelte} l/Jahr
          </span>
        </div>
        <div className="text-xs"></div>
        <hr />
        <div
          className="absolute text-lime-500 text-2xl top-6 right-1 z-20"
          onClick={() => {
            setToggleN(!toggleN);
          }}
        >
          <HiDotsHorizontal></HiDotsHorizontal>
        </div>
        {!toggleN && (
<div className="">
<div className="text-sm my-1  gap-1">
            <label>
            Niederschalagsmenge l/Jahr{" "}
            </label>
            
         <div className="flex justify-center gap-1">
         <input
          disabled={isDisabledN}
              type="number"
              min="1"
              className="border border-lime-600 w-12"

            ></input>
            <AiOutlineSave size={20} className="text-lime-800" onClick={(() => setIsDisabledN(!isDisabledN))} ></AiOutlineSave>
         </div>
          </div>
          <div className="text-sm my-1  gap-1">
            <label>
            Niederschalagsmenge mm/Jahr{" "}
            </label>
            
         <div className="flex justify-center gap-1">
         <input
          disabled={isDisabledN}
              type="number"
              min="1"
              className="border border-lime-600 w-12"

            ></input>
            <AiOutlineSave size={20} className="text-lime-800" onClick={(() => setIsDisabledN(!isDisabledN))} ></AiOutlineSave>
         </div>
          </div>
</div>
          
          
        )}
      </div>
      }
    </div>
    
  );
};

export default NewHomeItem;
