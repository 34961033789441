import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data01 = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];
const data02 = [
  { name: "A1", value: 100 },
  { name: "A2", value: 300 },
  { name: "B1", value: 100 },
  { name: "B2", value: 80 },
  { name: "B3", value: 40 },
  { name: "B4", value: 30 },
  { name: "B5", value: 50 },
  { name: "C1", value: 100 },
  { name: "C2", value: 200 },
  { name: "D1", value: 150 },
  { name: "D2", value: 50 },
];



const GrafischeAusgabeBar2 = (props) => {

  const data = [
    {
      name: "Abflussleistung/Investitionssumme",
      Investitionssumme: props.value,
      Abflussleistung: props.saveWater,
      "Wasser gesamt": 3000,
    },
  ];
  return (
    <div className="mt-2">
      <div className=" ">
        <hr />

        <div className="w-[20px]">
          <BarChart
            width={200}
            height={220}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Abflussleistung" fill="#1f9abd" />
            {/* <Bar dataKey="amt" stackId="a" fill="#82ca9d" /> */}
            <Bar dataKey="Investitionssumme" fill="#84cc15" />
            {/* <Bar dataKey="euro" fill="#84cc15" />
            <Bar dataKey="Wasser gesamt" fill="#84cc15" /> */}
          </BarChart>
        </div>

        <div className="block"></div>
      </div>
    </div>
  );
};

export default GrafischeAusgabeBar2;
