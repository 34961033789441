import { ImCoinEuro } from "react-icons/im";
import {
  BsPatchQuestion,
  BsLayoutSidebarInsetReverse,
  BsReverseLayoutSidebarInsetReverse,
  BsFillTreeFill,
} from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import GrafischeAusgabe from "./GrafischeAusgabe";
import GrafischeAusgabeBar from "./GrafischeAusgabeBar";

const Amortisation = (props) => {
  return (
    <>
      {/* Amortyzacja */}

      <div className="flex  mt-5">
        <div className="w-full">
          <div className="flex gap-1  border px-4 py-2 rounded drop-shadow  text-white  bg-lime-500 hover:bg-lime-600 my-2">
            <div className="">
              <ImCoinEuro size={24}></ImCoinEuro>
            </div>
            <div className="">Amortisation</div>
          </div>
          <div className=" p-4 flex gap-3">
            <div className="w-3/5">
              <div className="">
                <table className="w-[80%]">
                <tr className="border-b bg-neutral-100">
                    <td className="py-5">Abflussleistung</td>
                    <td>
                    {props.saveWater}  Liter
                      <a id={"props-amo1"} className="absolute pl-1 pt-1">
                        <BsPatchQuestion></BsPatchQuestion>
                      </a>
                      <Tooltip
                        anchorId={"props-amo1"}
                        content={"Abflussleistung info"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="py-5">Abflusskosten</td>
                    <td>
                    {Math.round(props.saveWater*0.232)}€ / jährlich
                      <a id={"props-amo2"} className="absolute pl-1 pt-1">
                        <BsPatchQuestion></BsPatchQuestion>
                      </a>
                      <Tooltip
                        anchorId={"props-amo2"}
                        content={"Abflusskosten info"}
                      />
                    </td>
                  </tr>
                  <tr className="border-b bg-neutral-100">
                    <td className="py-5">Investitionssumme</td>
                    <td>
                    {props.value}€ / jährlich
                      <a id={"props-amo3"} className="absolute pl-1 pt-1">
                        <BsPatchQuestion></BsPatchQuestion>
                      </a>
                      <Tooltip
                        anchorId={"props-amo3"}
                        content={"Investitionssumme info"}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="py-5">Amortisation</td>
                    <td>
                      3,4 Jahre
                      <a id={"props-amo4"} className="absolute pl-1 pt-1">
                        <BsPatchQuestion></BsPatchQuestion>
                      </a>
                      <Tooltip
                        anchorId={"props-amo4"}
                        content={"Amortisation info"}
                      />
                    </td>
                  </tr>
                  <tr className="border-b bg-neutral-100">
                    <td className="py-5">Abflussleistung</td>
                    <td>
                      14.000 Liter
                      <a id={"props-amo5"} className="absolute pl-1 pt-1">
                        <BsPatchQuestion></BsPatchQuestion>
                      </a>
                      <Tooltip
                        anchorId={"props-amo5"}
                        content={"Abflussleistung info"}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="w-2/5">
              <GrafischeAusgabeBar saveWater={props.saveWater} value={props.value} ></GrafischeAusgabeBar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Amortisation;
