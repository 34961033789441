const HomeItem = ({ img, name, grund, hausf, versiegelung, a }) => {
  return (
    <div className={a}>
      <div class=" ">
        <div className="flex justify-center">
          <img src={"/" + img} alt="" />
        </div>
        <div className="text-center p-5 bg-gray-100 text-xl">{name}</div>
      </div>
    </div>
  );
};

export default HomeItem;
