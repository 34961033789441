import NavBar from "./NavBar";
import "react-slideshow-image/dist/styles.css";
import { Fade } from "react-slideshow-image";

const slideImages = [
  {
    url: "images/s11.jpg",
    caption: "Slide 1",
  },
  {
    url: "images/s2.jpg",
    caption: "Slide 2",
  },
  {
    url: "images/s3.jpg",
    caption: "Slide 3",
  },
  {
    url: "images/sl1.jpg",
    caption: "Slide 1",
  },
];

const Header = () => {
  return (
    <div className="">
      <div className="">
        <NavBar></NavBar>
      </div>

      <div className="slide-container">
        <Fade>
          {slideImages.map((slideImage, index) => (
            <div className="each-fade" key={index}>
              <div
                className="slider bg-cover bg-center"
                style={{ backgroundImage: `url(${slideImage.url})` }}
              >
                {/* <div className="w-1/2 mx-auto text-center pt-64 text-4xl uppercase">{slideImage.caption}</div> */}
              </div>
            </div>
          ))}
        </Fade>
        {/* <div className="logo">
          Logo
        </div> */}
      </div>
    </div>
  );
};

export default Header;
