import GrafischeAusgabeBar from "./GrafischeAusgabeBar";
import { CiSquareRemove } from "react-icons/ci";
import GrafischeAusgabeBar2 from "./GrafischeAusgabeBar2";


const Vergleichsliste = (props) => {
  
  // const removeVersion = (key) => {
  //   console.log(key);
  // }
  const removeVersion = (indexToDelete) => {
    props.setCompare(prevItems => prevItems.filter((item, index) => index !== indexToDelete));
  }
  return (
    <>
      <div className="py-5">
        <h2 className="text-3xl my-4 text-lime-600">Vergleichsliste</h2>

        <div className="verg_wrapper flex gap-3">
          <div className="w-1/4">
            <div className="text-white">.</div>
            <div className="py-5">
              <div className="py-2">Abflussleistung aktuell Liter</div>
              <div className="py-2">Abflusskosten aktuell €</div>
              <div className="py-2">Investitionen €</div>
              <div className="py-2">Ersparnis Abfluss Liter</div>
              <div className="py-2">Ersparnis Abfluss €</div>
              <div className="py-2">Amortisation Jahre</div>
            </div>
          </div>
          <div className="w-3/4 flex gap-2">
          {props.compare.map((item, key) => {
          //  console.log(item[0])
            return (
                <div className="w-1/3">
              {/* <div>
                {key} -- {item}
              </div> */}

              <div className="" key={key}>

                  <div className="text-center">Version {key+1}</div>
                  <div className=" p-5 text-center">

                          <div className="py-2 bg-neutral-100">
                          {item[0]}/ l
                          </div>
                          <div className="py-2">
                          {Math.round(item[0]*0.232)}
                          </div>
                          <div className="py-2  bg-neutral-100">
                            {/* {props.invest[1]} */}
                            {item[1]}
                          </div>
                          <div className="py-2">
                              ...
                          </div>
                          <div className="py-2  bg-neutral-100">
                              ...
                          </div>
                          <div className="py-2">
                              ...
                          </div>
                          <GrafischeAusgabeBar2 saveWater={item[0]} value={item[1]} ></GrafischeAusgabeBar2>
                  </div>
                <div className="flex justify-center text-red-600 font-bold" onClick={() => removeVersion(key)}><CiSquareRemove size={25}></CiSquareRemove></div>
                  {/* <GrafischeAusgabeBar></GrafischeAusgabeBar> */}
              </div>
              </div>
            );
          })}
        </div>
        </div>
      </div>
    </>
  );
};

export default Vergleichsliste;
