import NewItemImpr from "../components/NewItemImpr";
import NewItemImprSqr from "../components/NewItemImprSqr";
import Base from "../components/Base";
import { useState } from "react";
import Filter from "./Filter";
import { MdCompareArrows } from "react-icons/md";
import { HiDotsHorizontal } from "react-icons/hi";
import Slider from '@mui/material/Slider';
import { NumericFormat } from 'react-number-format';

import Amortisation from "../components/Amortisation";
import AddToCompare from "../components/AddToCompare";


const ImprAll = ({ imgInw, setCompare, compare, value, setValue }) => {
  // Filtr
 
  const [item, setItem] = useState(Base);
  const [cur, setCur] = useState();
  const [close, setClose] = useState(true);
  const menuItems = [...new Set(Base.map((Val) => Val.category))];

  const [saveWater, setSaveWater] = useState(0);
  const [showInvest, setShowInvest] = useState(false);

 

  const filterItem = (curcat) => {
    const newItem = Base.filter((newVal) => {
      return newVal.category === curcat;
    });
    setItem(newItem);
    setCur(curcat);
  };

  const saveCalcValue = () => {};

  const [clear, setClear] = useState();

  const clearCalcValue = () => {
    setSaveWater(0);
    setClear();
    setClose(false);
  };

  const addToCompare = () => {
    console.log('comp'+compare.length)
    if(compare.length < 3)
    {
      setCompare((compare) => [...compare, [saveWater,value]]);
    }
    else{
      alert('Zu viele Varianten zum Vergleichen')
    }
   
  };

  const nums = [1, 3, 5, 6, 7, 9, 13, 14, 15];

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue );
  };
  return (
    <div>
    <div className=" border-2 p-5 my-5 border-gray-100 bg-gray-100">
      {/* Ihre nachhaltigen Maßnahmen */}
      <h2 className="text-3xl my-4 text-lime-600" id="masnahmen">
        Ihre nachhaltigen Maßnahmen
      </h2>

      <div className="flex my-5 gap-2">
        <div className="w-1/4">
          <img src={imgInw} alt=""></img>
        </div>
        <div className="w-3/4  border p-4 bg-white ">
          
<div className="flex justify-center gap-5 items-center">
<div className="w-1/3 ">
            <div className="bg-lime-600 text-center p-3 rounded">
              <div className="text-white uppercase text-xl">
              Abwasser
              </div>
              <div className="text-white uppercase text-5xl font-bold">
              <NumericFormat displayType="text" value={saveWater}
                thousandsGroupStyle="lakh"
                 thousandSeparator= '.' decimalSeparator= ','
              />
             
              </div>
              <div className="text-white uppercase">LITER</div>
            </div>
          </div>
          <div className="w-1/3 ">
            <div className="bg-lime-500 text-center p-3 rounded">
              <div className="text-white uppercase text-xl">Ersparnis</div>
              <div className="text-white uppercase text-5xl font-bold">
              <NumericFormat displayType="text" value={Math.round(saveWater*0.232)}
                thousandsGroupStyle="lakh"
                 thousandSeparator= '.' decimalSeparator= ','
              />
              {/* {Math.round(saveWater*0.232)} */}
              </div>
              <div className="text-white uppercase">EURO</div>
            </div>
          </div>
          <div className="w-1/3 ">
            <div className="bg-lime-500 text-center p-3 rounded relative">
              <div className="text-white uppercase text-xl">Investitionssumme</div>
              <div className="text-white uppercase text-5xl font-bold">
              <NumericFormat displayType="text" value={value}
                thousandsGroupStyle="lakh"
                 thousandSeparator= '.' decimalSeparator= ','
              />
           
              </div>
              <div className="text-white uppercase">EURO</div>
              <div className="absolute right-2 text-white text-2xl bottom-2" onClick={() => setShowInvest(!showInvest)}><HiDotsHorizontal></HiDotsHorizontal></div>
              
              {showInvest && (<div className=" my-2">

              <Slider aria-label="Volume" 
              value={value} 
              onChange={handleChange} 
              color="primary"
              size="small"
            
              step={1000}
              min={1000}
              max={100000}
              valueLabelDisplay="auto"
              />
              </div>)}
           
            </div>
          </div>

</div>
          
          
     
          <div className="flex justify-center gap-5 w-full mt-5">
       
       <button
         className="py-2 px-4 rounded bg-gray-200 hover:bg-gray-300 flex"
         onClick={addToCompare}
       >
         Zur Vergleichsliste hinzufügen
       </button>

       <button
         className="py-2 px-4 rounded bg-gray-200 hover:bg-gray-300 "
         onClick={clearCalcValue}
       >
         Alle Maßnahmen deaktivieren
       </button>
    
   </div>
        </div>

      </div>

      {/*  */}

      <div>
        <Filter
          menuItems={menuItems}
          setItem={setItem}
          filterItem={filterItem}
          cur={cur}
          setCur={setCur}
        ></Filter>
      </div>
      <div className="container mx-auto">
        <div className="grid gap-5 grid-cols-2  md:grid-cols-3 xl:grid-cols-3 ">
          {item.map((Val) => {
            return (
              <div className="">
                {(() => {
                  if (nums.includes(Val.id)) {
                    return (
                      <NewItemImpr
                        impr={Val}
                        saveWater={saveWater}
                        setSaveWater={setSaveWater}
                        setClear={setClear}
                        close = {close}
                        setClose = {setClose}
                   
                      ></NewItemImpr>
                    );
                  } else {
                    return (
                      <NewItemImprSqr
                        impr={Val}
                        saveWater={saveWater}
                        setSaveWater={setSaveWater}
                        setClear={setClear}
                        close = {close}
                        setClose = {setClose} 
                        
                      ></NewItemImprSqr>
                    );
                  }

                  return null;
                })()}
              </div>
            );
          })}
        </div>
      </div>

    </div>
    <div className="">
            <Amortisation value = {value} saveWater={saveWater}></Amortisation>
        <AddToCompare></AddToCompare>
    </div>
    </div>
  );
};

export default ImprAll;
