const NavBar = () => {
  return (
    <div className="bg-lime-600 py-2 text-white uppercase">
      <div class="container mx-auto flex justify-between">
        <div className="logo2">
          <div className="logoimg">
            <img src="/logo.png" alt=""></img>
          </div>
        </div>

        <div className="flex gap-5">
          <div className="">
            <a href="#projektinfo"> Projektinfo</a>
          </div>
          <div className="">
            <a href="/#kalkulation">Kalkulation</a>
          </div>
          <div className="">
            <a href="/#masnahmen">Maßnahmen</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
